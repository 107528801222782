<template>
  <b-card
    class="chat-widget"
    no-body
  >
    <b-card-header>
      <b-card-title>Balances</b-card-title>
      <feather-icon
        @click="reloadData()"
        :icon="orderDesc ? 'ArrowDownIcon' : 'ArrowUpIcon'"
        size="18"
        class="text-muted cursor-pointer"
      />
    </b-card-header>

    <section class="chat-app-window">
      <vue-perfect-scrollbar
        ref="refChatLogPS"
        :settings="perfectScrollbarSettings"
        class="user-chats scroll-area"
      >
        <div class="chats">
          <div
            v-for="taxi in taxis"
            :key="taxi.id"
            class="chat-left mb-2"
          >
            <div class="chat-avatar">
              <b-media no-body>
                <b-media-aside>
                  <b-avatar
                    rounded
                    :variant="
                      taxi.balance < 0 ? 'light-danger' : 'light-success'
                    "
                  >
                    <feather-icon icon="DollarSignIcon" />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="w-100">
                  <h6>
                    {{ taxi.firstName }} {{ taxi.lastName }}
                    <small class="text-muted"> (#{{ taxi.licenceNum }})</small>
                  </h6>
                </b-media-body>
              </b-media>
            </div>
            <div class="chat-body">
              <p
                class="text-nowrap mb-50 text-right"
                :class="taxi.balance < 0 ? 'text-danger' : 'text-success'"
              >
                {{ formatCurrency(taxi.balance) }}
              </p>
            </div>
          </div>
        </div>
      </vue-perfect-scrollbar>

      <div class="chat-app-form">
        <b-form-input
          v-model="searchQuery"
          class="d-inline-block mr-1"
          placeholder="Press ENTER to search"
          @keyup.enter="initData"
        />
      </div>
    </section>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BCardText,
  BCol,
  BAvatar,
  BMedia,
  BMediaAside,
  BMediaBody,
  BPagination,
  BFormInput,
} from 'bootstrap-vue';
import { ref } from '@vue/composition-api';
import useDashboard from '@/modules/dashboard/composables/useDashboard';
import useUtils from '@/composables/useUtils';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';

export default {
  setup() {
    const { formatCurrency } = useUtils();
    const { getTaxiBalanceList } = useDashboard();

    const taxis = ref([]);
    const orderDesc = ref(false);
    const searchQuery = ref('');

    const reloadData = () => {
      orderDesc.value = !orderDesc.value;
      initData();
    };

    const initData = async () => {
      const params = {
        orderDesc: orderDesc.value,
        searchQuery: searchQuery.value,
      };

      const { data } = await getTaxiBalanceList(params);
      taxis.value = data;
    };

    initData();

    return {
      taxis,
      orderDesc,
      searchQuery,
      reloadData,
      formatCurrency,
      initData,
      perfectScrollbarSettings: {
        maxScrollbarLength: 150,
        wheelPropagation: false,
      },
    };
  },
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BCardText,
    BAvatar,
    BMedia,
    BMediaAside,
    BMediaBody,
    BPagination,
    BRow,
    BCol,
    BFormInput,
    VuePerfectScrollbar,
  },
};
</script>

<style lang="scss">
@import '@core/scss/base/pages/app-chat-list.scss';
</style>
