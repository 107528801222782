import { useToast } from 'vue-toastification/composition';
import store from '@/store';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

const useDashboard = () => {
  const toast = useToast();

  const getStatisticsOfTheYear = async (year) => {
    try {
      return await store.dispatch('dashboard/getAnnualStatistics', year);
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: 'Error getting the number of bookings of the year',
          icon: 'XCircleIcon',
        },
      });
    }
  };

  const getBookingCurrentMonth = async (date) => {
    try {
      return await store.dispatch('dashboard/getBookingNumberInAMonth', date);
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: 'Error getting the number of bookings of the month',
          icon: 'XCircleIcon',
        },
      });
    }
  };

  const getNumOfBookingsByDay = async (date) => {
    try {
      return await store.dispatch('dashboard/getNumOfBookingsByDay', date);
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: 'Error getting the number of bookings in a day',
          icon: 'XCircleIcon',
        },
      });
    }
  };

  const getAnnualCompaniesPaymentsSummary = async (year) => {
    try {
      return await store.dispatch(
        'dashboard/getAnnualCompaniesPaymentsSummary',
        year
      );
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: 'Error getting companies statistics',
          icon: 'XCircleIcon',
        },
      });
    }
  };

  const getTaxiBalanceList = async (params) => {
    try {
      return await store.dispatch(
        'dashboard/getTaxiBalanceList',
        params
      );
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: 'Error getting taxis statistics',
          icon: 'XCircleIcon',
        },
      });
    }
  };

  return {
    //Method
    getStatisticsOfTheYear,
    getBookingCurrentMonth,
    getNumOfBookingsByDay,
    getAnnualCompaniesPaymentsSummary,
    getTaxiBalanceList,
  };
};

export default useDashboard;
