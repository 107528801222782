<template>
  <b-card no-body>
    <b-card-header>
      <h4 class="mb-0">
        Annual Companies Payments
      </h4>
      <b-card-text class="font-medium-5 mb-0">
        <feather-icon
          @click="reloadData()"
          icon="RefreshCwIcon"
          size="18"
          class="text-muted cursor-pointer"
        />
      </b-card-text>
    </b-card-header>

    <b-card-body>

      <!-- apex chart -->
      <vue-apex-charts
        type="radialBar"
        height="325"
        :options="productOrdersRadialBar.chartOptions"
        :series="productOrdersRadialBar.series"
      />

      <div class="d-flex justify-content-center">
        <b-dropdown
          :text="`${yearSelected}`"
          size="sm"
          class="budget-dropdown"
          variant="outline-primary"
        >
          <b-dropdown-item
            v-for="year in lastThreeYears"
            :key="year"
            @click="yearSelectedChange(year)"
          >
            {{ year }}
          </b-dropdown-item>
        </b-dropdown>
      </div>

      <!-- chart info -->
      <div
        v-for="(data, key, index) in chartInfo"
        :key="key"
        class="d-flex justify-content-between"
        :class="index === Object.keys(chartInfo).length - 1 ? '' : 'mb-1'"
      >
        <div class="series-info d-flex align-items-center">
          <feather-icon
            icon="CircleIcon"
            size="16"
            :class="key === 'total' ? 'text-dark' : key === 'paid' ? 'text-success' : 'text-warning'"
          />
          <span class="font-weight-bold text-capitalize ml-75">{{ key }}</span>
        </div>

        <span>{{ data }}</span>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import { $themeColors } from '@themeConfig'
import { BCard, BCardHeader, BCardTitle, BDropdown, BDropdownItem, BCardBody, BCardText } from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import useDashboard from '@/modules/dashboard/composables/useDashboard'
import VueApexCharts from 'vue-apexcharts'

export default {
  setup() {
    const { getAnnualCompaniesPaymentsSummary } = useDashboard();

    const currentYear = new Date().getFullYear();
    const lastThreeYears = [currentYear, currentYear - 1, currentYear - 2]

    const yearSelected = ref(currentYear)
    const chartInfo = ref({ total: 0, paid: 0, pending: 0 });
    const productOrdersRadialBar = ref({
      series: [0, 0],
      chartOptions: {
        labels: ['Paid', 'Pending'],
        plotOptions: {
          radialBar: {
            size: 150,
            hollow: {
              size: '40%',
            },
            track: {
              strokeWidth: '100%',
              margin: 15,
            },
            dataLabels: {
              value: {
                fontSize: '1rem',
                colors: '#5e5873',
                fontWeight: '500',
                offsetY: 5,
              },
            },
          },
        },
        colors: [$themeColors.success, $themeColors.warning],
        stroke: {
          lineCap: 'round',
        },
        chart: {
          height: 355,
          dropShadow: {
            enabled: true,
            blur: 3,
            left: 1,
            top: 1,
            opacity: 0.1,
          },
        },
      },
    });

    const yearSelectedChange = (year) => {
      yearSelected.value = year;
      initData();
    }

    const reloadData = () => {
      initData();
    }

    const initData = async () => {
      const { total, pending, paid } = await getAnnualCompaniesPaymentsSummary(yearSelected.value)

      chartInfo.value.total = total;
      chartInfo.value.paid = paid;
      chartInfo.value.pending = pending;

      const paidPercentage = total == 0 ? 0 : paid * 100 / total;
      const pendingPercentage = total == 0 ? 0 : pending * 100 / total;

      productOrdersRadialBar.value.series = [
        paidPercentage.toFixed(0),
        pendingPercentage.toFixed(0)
      ]
    }

    initData();

    return {
      lastThreeYears,
      yearSelected,
      yearSelectedChange,
      chartInfo,
      productOrdersRadialBar,
      reloadData
    }
  },
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardBody,
    BCardText
  },
}
</script>
