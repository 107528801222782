<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Annual Booking Statistics</b-card-title>
      <div class="d-flex align-items-center justify-content-end">
        <b-dropdown
          :text="`${yearSelected}`"
          size="sm"
          class="pr-1 budget-dropdown"
          variant="outline-primary"
        >
          <b-dropdown-item
            v-for="year in lastThreeYears"
            :key="year"
            @click="yearSelectedChange(year)"
          >
            {{ year }}
          </b-dropdown-item>
        </b-dropdown>

        <feather-icon
          @click="reloadGraph()"
          icon="RefreshCwIcon"
          size="18"
          class="text-muted cursor-pointer"
        />
      </div>

    </b-card-header>

    <b-card-body
      v-if="graphConfig"
      class="pb-0"
    >
      <div class="d-flex justify-content-start mb-3">
        <div
          class="mr-2"
          v-for="stat in statisticData"
        >
          <b-card-text class="mb-50">
            Total {{ stat.bookingType.name }}
          </b-card-text>
          <h3
            class="font-weight-bolder"
            :class="`text-${getBookingTypeVariantAndName(stat.bookingType).variant}`"
          >{{ getTotalAnnual(stat.total) }}</h3>
        </div>
      </div>

      <!-- apex chart -->
      <vue-apex-charts
        type="line"
        height="240"
        :options="graphConfig.chartOptions"
        :series="graphConfig.series"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardHeader, BCardTitle, BDropdown, BDropdownItem, BCardBody, BCardText } from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import VueApexCharts from 'vue-apexcharts'
import useDashboard from '@/modules/dashboard/composables/useDashboard'
import useDashboardUtils from '@/modules/dashboard/composables/useDashboardUtils'
import useBookingUtils from '@/modules/booking/composables/useBookingUtils'

export default {
  setup() {
    const { getStatisticsOfTheYear } = useDashboard();
    const { populateGraphData } = useDashboardUtils();
    const { getBookingTypeVariantAndName } = useBookingUtils();

    const currentYear = new Date().getFullYear();
    const lastThreeYears = [currentYear, currentYear - 1, currentYear - 2]

    const yearSelected = ref(currentYear)
    const totalCrew = ref(0);
    const totalPax = ref(0)
    const graphConfig = ref(null)
    const statisticData = ref(null)

    const yearSelectedChange = (year) => {
      yearSelected.value = year;
      getGraphData();
    }

    const reloadGraph = () => {
      getGraphData();
    }

    const getTotalAnnual = (totals) => {
      return totals.reduce((total, monthTotal) => total + monthTotal, 0)
    }

    const getGraphData = async () => {
      const data = await getStatisticsOfTheYear(yearSelected.value);
      const graphSeries = data.map(({ bookingType, total: data }) => ({ name: bookingType.name, data }))

      statisticData.value = data;
      graphConfig.value = populateGraphData(graphSeries);
    }

    getGraphData();

    return {
      lastThreeYears,
      yearSelected,
      yearSelectedChange,
      totalCrew,
      totalPax,
      graphConfig,
      statisticData,
      reloadGraph,
      getTotalAnnual,
      getBookingTypeVariantAndName,
    }
  },
  components: {
    VueApexCharts,
    BDropdown,
    BDropdownItem,
    BCardText,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
  }
}
</script>
