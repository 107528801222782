<template>
  <b-card
    no-body
    class="card-statistics"
  >
    <b-card-header>
      <b-card-title>Daily Booking Statistics</b-card-title>
      <div class="d-flex align-items-center justify-content-end">
        <flat-pickr
          v-model="searchByDate"
          class="form-control mr-1"
          :config="{ dateFormat: 'd/m/Y', enableTime: false }"
          readonly="false"
          placeholder="Date"
        />

        <feather-icon
          @click="reload()"
          icon="RefreshCwIcon"
          size="18"
          class="text-muted cursor-pointer"
        />
      </div>
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-row>
        <b-col
          v-for="bookings in dailyBookings"
          :key="bookings.bookingTypeId"
          class="mb-2 mb-md-0"
        >
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar
                size="48"
                :variant="`light-${
                  getBookingTypeVariantAndName(bookings.bookingType).variant
                }`"
              >
                <feather-icon
                  size="24"
                  icon="FileIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ bookings.total }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ bookings.bookingType.name }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import { ref, watch } from '@vue/composition-api';
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
} from 'bootstrap-vue';
import useDashboard from '@/modules/dashboard/composables/useDashboard';
import useBookingUtils from '@/modules/booking/composables/useBookingUtils';
import flatPickr from 'vue-flatpickr-component';
import useUtils from '@/composables/useUtils';

export default {
  setup() {
    const { convertDateToUTC } = useUtils();
    const { getNumOfBookingsByDay } = useDashboard();
    const { getBookingTypeVariantAndName } = useBookingUtils();

    const dailyBookings = ref(null);
    const searchByDate = ref(null);

    const reload = () => {
      initData();
    };

    const initData = async () => {
      const { bookings } = await getNumOfBookingsByDay(convertDateToUTC(searchByDate.value));
      dailyBookings.value = bookings;
    };

    watch(searchByDate, () => {
      reload()
    });

    initData();

    return {
      dailyBookings,
      searchByDate,
      getBookingTypeVariantAndName,
      reload,
    };
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    flatPickr,
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
