import { $themeColors } from '@themeConfig';

const useDashboardUtils = () => {
  const formatAnnualStatisticArr = (arrayData) => {
    let arrayDataFormatted = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    arrayData.forEach((monthData) => {
      arrayDataFormatted[monthData.month - 1] = monthData.total;
    });

    return arrayDataFormatted;
  };

  const populateGraphData = (series) => {
    return {
      series,
      chartOptions: {
        chart: {
          toolbar: { show: false },
          zoom: { enabled: false },
          offsetX: -10,
        },
        stroke: {
          curve: 'smooth',
          width: [3, 3],
        },
        legend: {
          show: false,
        },
        colors: [$themeColors.warning, $themeColors.success],
        markers: {
          size: 0,
          hover: {
            size: 5,
          },
        },
        xaxis: {
          labels: {
            style: {
              colors: '#b9b9c3',
              fontSize: '1rem',
            },
          },
          axisTicks: {
            show: false,
          },
          categories: [
            'Jan.',
            'Feb.',
            'Mar.',
            'Apr.',
            'May.',
            'Jun.',
            'Jul.',
            'Aug.',
            'Sep.',
            'Oct.',
            'Nov.',
            'Dec.',
          ],
          axisBorder: {
            show: false,
          },
          tickPlacement: 'on',
        },
        yaxis: {
          tickAmount: 5,
          labels: {
            style: {
              colors: '#b9b9c3',
              fontSize: '1rem',
            },
            formatter(val) {
              return val > 999 ? `${(val / 1000).toFixed(0)}k` : val;
            },
          },
        },
        grid: {
          borderColor: '#e7eef7',
          padding: {
            top: -20,
            bottom: -10,
            left: 20,
          },
        },
        tooltip: {
          x: { show: false },
        },
      },
    };
  };

  return {
    formatAnnualStatisticArr,
    populateGraphData,
  };
};

export default useDashboardUtils;
