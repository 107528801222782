<template>
  <div>
    <bookings-current-month />
    
    <bookings-by-day />

    <annual-graph />

    <b-row>
      <b-col md="6">
        <annual-companies-transactions />
      </b-col>
      <b-col md="6">
        <taxi-balance-list />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import AnnualCompaniesTransactions from '@/modules/dashboard/components/AnnualCompaniesTransactions.vue';
import AnnualGraph from '@/modules/dashboard/components/AnnualGraph.vue'
import TaxiBalanceList from '@/modules/dashboard/components/TaxiBalanceList.vue';
import BookingsCurrentMonth from '@/modules/dashboard/components/BookingsCurrentMonth.vue'
import BookingsByDay from '@/modules/dashboard/components/BookingsByDay.vue'

export default {
  components: {
    BRow,
    BCol,
    AnnualGraph,
    BookingsCurrentMonth,
    BookingsByDay,
    AnnualCompaniesTransactions,
    TaxiBalanceList
  },
}
</script>
