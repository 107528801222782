<template>
  <b-card
    no-body
    class="card-statistics"
  >
    <b-card-header>
      <b-card-title>Monthly Booking Statistics</b-card-title>
      <feather-icon
        @click="reload()"
        icon="RefreshCwIcon"
        size="18"
        class="text-muted cursor-pointer"
      />
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-row>
        <b-col
          v-for="bookings in monthlyBookings"
          :key="bookings.bookingTypeId"
          class="mb-2 mb-md-0"
        >
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar
                size="48"
                :variant="`light-${getBookingTypeVariantAndName(bookings.bookingType).variant}`"
              >
                <feather-icon
                  size="24"
                  icon="FileIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ bookings.total }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ bookings.bookingType.name }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import { ref } from '@vue/composition-api';
import { BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody, } from 'bootstrap-vue'
import useDashboard from '@/modules/dashboard/composables/useDashboard';
import useBookingUtils from '@/modules/booking/composables/useBookingUtils';

export default {
  setup() {
    const { getBookingCurrentMonth } = useDashboard();
    const { getBookingTypeVariantAndName } = useBookingUtils();

    const monthlyBookings = ref(null)

    const reload = () => {
      initData();
    }

    const initData = async () => {
      const { bookings } = await getBookingCurrentMonth(new Date())
      monthlyBookings.value = bookings;
    }

    initData();

    return {
      monthlyBookings,
      getBookingTypeVariantAndName,
      reload
    }
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  }
}
</script>
